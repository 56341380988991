import {Injectable, NgZone} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';


export interface CustomNotify {
  timeout?: number;
  action?: {
    text: string;
    onClick(): any;
  };
}

@Injectable()
export class NotificationService {

  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    protected ngZone: NgZone
  ) {
  }

  private show(message: string, type?: string, callback?: any, custom?: CustomNotify) {
    const config = new MatSnackBarConfig<{ action: { text: string; onClick: () => void; } }>();
    config.announcementMessage = message;
    config.panelClass = type ? `snackbar-${type}` : 'snackbar-error';
    config.duration = custom?.timeout ?? 5000;

    // todo reimplement logic of callback and action
    // const action = custom?.action || {
    //   text: 'X', onClick: () => {
    //   }
    // };

    const textOnlySnackBarMatSnackBarRef = this._snackBar.open(message, '', config); // Consolidated parameters

    callback?.(textOnlySnackBarMatSnackBarRef);
  }

  notify(msgCode?: string, type?: string, custom?: CustomNotify, callback?: any, value?: object) {
    this.translate.get(msgCode ? msgCode : 'global.default_error_message', value ? value : undefined)
      .subscribe(
        (translation: string) => {
          this.ngZone.run(() => {
            this.show(translation, type, callback, custom);
          });
        },
        (err: Error) => {
          this.notify('Ooops! Something went wrong.');
          console.log(err)
        }
      );
  }

  // removeById(id: string) {
  // this.snakbar.remove(id);
  // }
}
