<footer>
  <section class="container">
    <section class="row">
      <section class="col-sm-4 col-md-3">
        <img width="100" src="/assets/images/footer-logo.svg" alt="TailorDirectory">
      </section>
      <section class="col-sm-4 col-md-6 text-center">
        <p class="title">&copy; {{year}} Tailor Directory</p>
      </section>
      <section class="col-sm-4 col-md-3"></section>
    </section>
  </section>
</footer>
